import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  GridBorderBottom,
  Label,
  StyledExpansionPanel,
  SectionTitle,
  GridNoBorder,
  SectionContainer,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { CollegeSavingsPlans } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';
import { formatter, prepareValue } from '../../constants/utils';

const P1 = styled.p`
  margin: 0;
  color: ${colors.blue1};
  font-weight: bold;
  font-size: 25px;
`;

const VerticalLine = styled.hr`
  width: 0;
  height: 30px;
  margin: 0 20px;
`;

const TitleGrid = styled(Grid)`
  width: 50%;
`;

const TitleContainer = styled(Grid)`
  background-color: ${colors.gray1};
  padding: 0 36px;
`;

const Title = styled.h5`
  margin: 10px 0;
  font-weight: normal;
  color: ${colors.blue3};
  width: 60%;
`;

const ValueHeader = styled.h5`
  margin: 10px 0;
  font-weight: bold;
  color: ${colors.blue3};
`;

interface CollegeSavingsProps {
  collegeSavings: CollegeSavingsPlans;
}

const CollegeSavingsSection: React.FunctionComponent<CollegeSavingsProps> = ({ collegeSavings }) => {
  const hasSavings =
    collegeSavings &&
    collegeSavings.college_savings_monthly_target &&
    collegeSavings.college_savings_monthly_target.length > 0;

  let total = 0;
  let totalPerMonth = 0;

  if (hasSavings) {
    collegeSavings.college_savings_monthly_target.forEach(cs => {
      totalPerMonth += prepareValue(cs.amount_to_save);
      total += prepareValue(cs.amount_saved);
    });
  }

  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>College Savings</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <TitleContainer container direction="row" justifyContent="space-between">
            <Title style={{ width: '45%' }}>CHILD</Title>
            <ValueHeader style={{ width: '25%' }}>Tot. Saved Amt</ValueHeader>
            <ValueHeader style={{ width: '20%' }}>Req. Monthly Savings</ValueHeader>
          </TitleContainer>
          <SectionContainer container direction="column" justifyContent="space-between">
            {hasSavings ? (
              collegeSavings.college_savings_monthly_target.map((collegeSaving, index) => (
                <GridBorderBottom container direction="row" justifyContent="space-between" alignItems="center" key={index}>
                  <TitleGrid item style={{ width: '50%' }}>
                    <Label>{`${collegeSaving.child_name}, ${collegeSaving.current_age} years old`}</Label>
                  </TitleGrid>
                  <Grid item style={{ width: '20%' }}>
                    <P1>{collegeSaving.amount_saved}</P1>
                  </Grid>
                  <VerticalLine color={colors.gray2} />
                  <Grid item style={{ width: '20%' }}>
                    <P1>{collegeSaving.amount_to_save}</P1>
                  </Grid>
                </GridBorderBottom>
              ))
            ) : (
              <EmptySectionMessage message={'hasn’t completed the ‘College Savings’ section yet.'} />
            )}
            {hasSavings ? (
              <GridNoBorder container direction="row" justifyContent="space-between" alignItems="center">
                <TitleGrid item style={{ width: '50%' }}>
                  <Label>TOTAL SAVINGS</Label>
                </TitleGrid>
                <Grid item style={{ width: '20%' }}>
                  <P1>{formatter.format(total)}</P1>
                </Grid>
                <VerticalLine color={colors.gray2} />
                <Grid item style={{ width: '20%' }}>
                  <P1>{formatter.format(totalPerMonth)}</P1>
                </Grid>
              </GridNoBorder>
            ) : null}
          </SectionContainer>
        </Grid>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { CollegeSavingsSection };
