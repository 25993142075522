// resources
import { useEffect } from 'react';

import * as React from 'react';
import { useLocation } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { format, formatDistanceStrict, parse } from 'date-fns';
import { ApplicationState } from '../store';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {MailTo} from '../models/MailTo'
import { UserSummaryActionCreators, UserSummaryState } from '../store/userSummary';
import { UserOverviewSection } from '../components/userDetails/userOverviewSection';
import { UserSummarySection } from '../components/userDetails/userSummarySection';
import { NetWorthEstimateSection } from '../components/userDetails/netWorthEstimateSection';
import { colors } from '../constants/colors';
import { InsuranceSection } from '../components/userDetails/insuranceSection';
import { EstatePlanningSection } from '../components/userDetails/estatePlanningSection';
import { MyAccountsSection } from '../components/userDetails/myAccountsSection';
import { DebtSection } from '../components/userDetails/debtSection';
import { TaxesSection } from '../components/userDetails/taxesSection';
// import { CashFlowSection } from '../components/userDetails/cashFlowSection';
import { InvestmentProfileSection } from '../components/userDetails/investmentProfileSection';
import { CollegeSavingsSection } from '../components/userDetails/collegeSavingsSection';
import { TodoListSection } from '../components/userDetails/todoListSection';
import { LinearProgress } from "@mui/material";

const PageContainer = styled.div`
  margin: 0;
  padding: 0;
  max-width: 850px;
`;

const NameText = styled(Typography)`
  && {
    font-size: 28px;
    font-weight: bold;
    color: ${colors.blue2};
  }
`;

const LoadingText = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: normal;
    color: ${colors.blue2};
    text-align: center;
    padding: 10px;
  }
`;
const TextLink = styled(Link)`
  color: ${colors.blue2};
  text-decoration: none;
  font-weight: normal;
  color: ${colors.blue4};
  background-color: ${colors.gray1};
  border-color: ${colors.blue4};
  border-radius: 5px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 19px 0;
`;

const DownloadButton = styled(Button)`
  .MuiButton-label {
    text-transform: none;
    color: ${colors.blue1};
  }
  ,
  &&:hover {
    background-color: transparent;
    outline: none;
  }
`;

interface LocationState {
  from: {
    pathname: string;
  }
  page: number;
}

function MailLink({ email, subject, body, username }: MailTo) {
  return (
      <NameText>
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}  >
          {username}
        </a>
      </NameText>
  );
}

type OwnProps = UserSummaryState & typeof UserSummaryActionCreators;

const UserDetailsPage: React.FunctionComponent<OwnProps> = ({ requestUserSummary, userSummary, loading, getPDF }) => {
  const { pathname, state } = useLocation<LocationState>();

  useEffect(() => {
    window.scrollTo(0, 0);
    requestUserSummary(pathname);
  }, [requestUserSummary, pathname]);

  let age = '';
  if (userSummary && userSummary.overview.date_of_birth) {
    age =
      formatDistanceStrict(parse(userSummary.overview.date_of_birth, 'MM-dd-yyyy', new Date()), new Date(), {
        roundingMethod: 'floor',
      }) +
      ' old - ' +
      format(parse(userSummary.overview.date_of_birth, 'MM-dd-yyyy', new Date()), 'MM/dd/yyyy');
  }

  const username = userSummary
    ? userSummary.user_data.first_name
      ? userSummary.user_data.first_name + ' ' + userSummary.user_data.last_name
      : userSummary.overview.email
    : '';

  const splitPathname = pathname.split("/");
  splitPathname.pop();
  splitPathname.shift();
  splitPathname[0] = "summary_pdf";
  const summaryPDFPathname = splitPathname.join("/");

  return (
    <PageContainer>
      <TextLink to={{ pathname: '/dashboard', state: { page: state.page } }}>
        {'<'} Back to all users
      </TextLink>
      {userSummary && !loading ? (
        <>
          <TopContainer>
            <MailLink email={userSummary.overview.email} subject="{ insert subject here }" body="{ insert email body here}" username={username} />
            <DownloadButton onClick={() => getPDF(summaryPDFPathname)}>Download PDF summary</DownloadButton>
          </TopContainer>
          <UserSummarySection userData={userSummary.user_data} investmentProfile={userSummary.investmentProfile} />
          <UserOverviewSection age={age} username={username} overview={userSummary.overview} />
          <TodoListSection todoList={userSummary.todos} />
          <NetWorthEstimateSection netWorthEstimate={userSummary.netWorthEstimate} />
          <MyAccountsSection accounts={userSummary.myAccounts} />
          {/* TODO: Need to work on CashFlowSection - not working properly */}
          {/*<CashFlowSection cashFlow={userSummary.cashFlow} />*/}
          <InvestmentProfileSection investmentProfile={userSummary.investmentProfile} />
          <DebtSection debt={userSummary.debt} />
          <InsuranceSection insurance={userSummary.insurance} />
          <EstatePlanningSection estatePlanning={userSummary.estatePlanning} />
          <CollegeSavingsSection collegeSavings={userSummary.collegeSavingsPlans} />
          <TaxesSection taxes={userSummary.incomeTax} />
        </>
      ) :
          <div>
            <LinearProgress />
            <LoadingText>Loading...</LoadingText>
          </div>

          }
    </PageContainer>
  );
};

export default connect(
  (state: ApplicationState) => state.userSummary,
  UserSummaryActionCreators,
)(UserDetailsPage as any);
