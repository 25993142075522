import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import { Label, StyledExpansionPanel, SectionTitle, SubLabel, SectionContainer } from '../styledComponents';
import { colors } from '../../constants/colors';
import { Insurance } from '../../models/UserSummary';
import { isObjectEmpty } from '../../constants/utils';
import EmptySectionMessage from './emptySectionMessage';

const GridBorderTop = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 20px 5px;
  width: 45%;
`;

interface InsuranceProps {
  insurance: Insurance;
}

const InsuranceSection: React.FunctionComponent<InsuranceProps> = ({ insurance }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Insurance</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        {insurance && !isObjectEmpty(insurance) ? (
          <SectionContainer container direction="column" justifyContent="space-between">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Needs life insurance?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{insurance.life_insurance_coverage_target}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Needs disability coverage?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{insurance.proper_disability_coverage}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Needs umbrella coverage?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{insurance.umbrella_coverage}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Needs to review property &amp; casualty?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{insurance.property_and_casualty_coverage}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
            </Grid>
          </SectionContainer>
        ) : (
          <SectionContainer container direction="column" justifyContent="space-between">
            <EmptySectionMessage message={'hasn’t completed the ‘Insurance’ section yet.'} />
          </SectionContainer>
        )}
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { InsuranceSection };
