import { AuthReducer, AuthState } from './authStore';
import { DashboardReducer, DashboardState } from './dashboard';
import { UserSummaryReducer, UserSummaryState } from './userSummary';

export interface ApplicationState {
  auth: AuthState;
  dashboard: DashboardState;
  userSummary: UserSummaryState;
}

export const reducers = {
  auth: AuthReducer,
  dashboard: DashboardReducer,
  userSummary: UserSummaryReducer,
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
