import { useEffect } from 'react';
import * as React from 'react';
import {Grid, SelectChangeEvent} from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { Label, FilterContainer, FilterFormControl, StyledMenuItem } from '../styledComponents';
import { colors } from '../../constants/colors';
import { badgeAwarded } from '../../constants/constants';
import { BaseFilter } from './baseFilter';
import { usePrevious } from '../../hooks/usePrevious';

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
            width: 250,
        },
    },
};

interface UserBadgeProps {
    onClickButton: any;
    filters: any;
    setFiltersState: any;
}


const BadgesFilter: React.FunctionComponent<UserBadgeProps> = ({ onClickButton, filters, setFiltersState }) => {
    const handleChangeBadges = (event: SelectChangeEvent<string>)  => {
        const val = event.target.value as string;
        setFiltersState({ badges: val });
    };
    const badgesLength = usePrevious(filters.badges.length) || 0;

    useEffect(() => {
        if (
            badgesLength !== filters.badges.length
        ) {
            onClickButton();
        }
    }, [
        badgesLength,
        filters,
        onClickButton,
    ]);

    return (
        <BaseFilter title={'Badges'}>
            <Grid container direction="row" justifyContent="flex-start">
                <FilterContainer container direction="column" justifyContent="space-between">
                    <Grid item>
                        <Label>Badges Awarded</Label>
                    </Grid>
                    <Grid item>
                        <FilterFormControl variant="outlined">
                            <InputLabel id="badges-mutiple-checkbox-label">Select Badge(s)</InputLabel>
                            <Select
                                id="badges-multiple-checkbox"
                                multiple
                                variant={'outlined'}
                                value={filters.badges}
                                onChange={handleChangeBadges}
                                input={<Input />}
                                renderValue={ selected => { if (selected.length === 1) { return selected} else { return "Multiple Badges" }}}
                                MenuProps={MenuProps}
                                IconComponent={ExpandMoreIcon}
                                >
                                {badgeAwarded.map(name => (
                                    <StyledMenuItem key={name} value={name}>
                                        <Checkbox
                                            checked={filters.badges.indexOf(name) > -1}
                                            color="primary"
                                            style={{ color: colors.blue1 }}
                                        />
                                        <ListItemText primary={name} color={colors.blue2} />
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </FilterFormControl>
                    </Grid>
                </FilterContainer>
            </Grid>
        </BaseFilter>
    );
};

export { BadgesFilter };
