import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  GridNoBorder,
  Label,
  StyledExpansionPanel,
  SectionTitle,
  SubLabel,
  StyledGridBorderBottom,
  SectionContainer,
  TitleContainer,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { MyAccounts } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';

const Title = styled.h5`
  margin: 10px 0;
  font-weight: normal;
  color: ${colors.blue3};
`;

interface AccountsProps {
  accounts: MyAccounts;
}

const MyAccountsSection: React.FunctionComponent<AccountsProps> = ({ accounts }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>My Accounts</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {!accounts || (accounts && accounts.asset_accounts.length < 1 && accounts.debt_accounts.length < 1) ? (
            <SectionContainer container direction="column" justifyContent="space-between">
              <EmptySectionMessage message={'hasn’t linked any accounts yet.'} />
            </SectionContainer>
          ) : (
            <>
              <TitleContainer>
                <Title>ASSETS ACCOUNTS</Title>
              </TitleContainer>
              <SectionContainer container direction="column" justifyContent="space-between">
                {accounts.asset_accounts && accounts.asset_accounts.length > 0 ? (
                  accounts.asset_accounts.map((account, index) => (
                    <StyledGridBorderBottom container direction="row" justifyContent="space-between" key={index}>
                      <Grid item style={{ width: '60%' }}>
                        <Label>{account.name}</Label>
                      </Grid>
                      <Grid item style={{ width: '20%' }}>
                        <SubLabel>{account.type}</SubLabel>
                      </Grid>
                      <Grid item style={{ width: '20%' }}>
                        <Label>{account.value_string}</Label>
                      </Grid>
                    </StyledGridBorderBottom>
                  ))
                ) : (
                  <GridNoBorder container direction="row" justifyContent="center">
                    <Label>No Asset Account</Label>
                  </GridNoBorder>
                )}
              </SectionContainer>
              <TitleContainer>
                <Title>DEBT ACCOUNTS</Title>
              </TitleContainer>
              <SectionContainer container direction="column" justifyContent="space-between">
                {accounts.debt_accounts && accounts.debt_accounts.length > 0 ? (
                  accounts.debt_accounts.map((account, index) => (
                    <StyledGridBorderBottom container direction="row" justifyContent="space-between" key={index}>
                      <Grid item style={{ width: '60%' }}>
                        <Label>{account.name}</Label>
                      </Grid>
                      <Grid item style={{ width: '20%' }}>
                        <SubLabel>{account.type}</SubLabel>
                      </Grid>
                      <Grid item style={{ width: '20%' }}>
                        <Label>{account.value_string}</Label>
                      </Grid>
                    </StyledGridBorderBottom>
                  ))
                ) : (
                  <GridNoBorder container direction="row" justifyContent="center">
                    <Label>No Debt Account</Label>
                  </GridNoBorder>
                )}
              </SectionContainer>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { MyAccountsSection };
