import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  Label,
  StyledExpansionPanel,
  SectionTitle,
  SectionContainer,
  GridBorderTop,
  VerticalDivider,
  GridLabel,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { NetWorthEstimate } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';
import { isObjectEmpty } from '../../constants/utils';

const P1 = styled.p`
  margin: 0;
  color: ${colors.blue1};
  font-weight: bold;
  font-size: 25px;
`;

interface NetWorthEstimateProps {
  netWorthEstimate: NetWorthEstimate;
}

const NetWorthEstimateSection: React.FunctionComponent<NetWorthEstimateProps> = ({ netWorthEstimate }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Net Worth Estimate</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <SectionContainer container direction="column" justifyContent="space-between">
          {netWorthEstimate && !isObjectEmpty(netWorthEstimate) ? (
            <GridBorderTop container direction="row" justifyContent="space-between">
              <Grid item>
                <Label>TOTAL ASSETS</Label>
                <P1>{netWorthEstimate.total_assets_est}</P1>
              </Grid>
              <Grid item>
                <Label>TOTAL DEBT</Label>
                <P1>{netWorthEstimate.total_debt_est}</P1>
              </Grid>
              <Grid item>
                <VerticalDivider orientation="vertical" color={colors.gray2} />
              </Grid>
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <GridLabel item>
                    <Label>TOTAL NET WORTH</Label>
                  </GridLabel>
                  <Grid item>
                    <P1>{netWorthEstimate.total_net_worth_est}</P1>
                  </Grid>
                </Grid>
              </Grid>
            </GridBorderTop>
          ) : (
            <EmptySectionMessage message={'hasn’t completed the ‘Net Worth Snapshot’ section yet.'} />
          )}
        </SectionContainer>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { NetWorthEstimateSection };
