import { useState } from 'react';
import * as React from 'react';
import styled from 'styled-components';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { colors } from '../constants/colors';
import { DialogActions } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { DisabledText } from './styledComponents';

const NewFilterContainer = styled(List)`
  && {
    padding: 50px 16px 70px;
  }
`;

const NewFilterCard = styled(Card)`
  && {
    border-radius: 17px;
    background-color: ${colors.white};
  }
  padding: 20px 10px;
`;

const Title = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  color: ${colors.blue2};
`;

const DialogHeader = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: ${colors.blue2};
`;

const Subtitle = styled.p`
  margin: 10px 0;
  color: ${colors.blue2};
  font-size: 16px;
`;

const StyledButton = styled(Button)`
  && {
    color: #ffffff;
    background-color: ${colors.blue4};
    border-radius: 11px;
    margin-top: 10px;
    padding: 12px 16px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
  }
`;

const CancelButton = styled(Button)`
  && {
    border-radius: 11px;
    margin-top: 10px;
    padding: 12px 16px;
    width: 100%;
    text-transform: none;
  }
`;

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: 464px;
    width: 472px;
    padding: 30px 25px 50px;
    border-radius: 0;
  }
`;

const StyledTextField = styled(TextField)`
  border-radius: 11px;
  background-color: ${colors.gray3};
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 8px 24px;
  }
`;

// TODO Needs to connect to backend
const CustomFilterCard: React.FunctionComponent = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const handleClose = () => {
    setOpen(false);
    setText('');
  };
  return (
    <div>
      <NewFilterContainer>
        <NewFilterCard variant="outlined">
          <CardContent>
            <Title>Not seeing the filters you&apos;re looking for?</Title>
            <Subtitle>We can output a filtered list based on your specific needs.</Subtitle>
            <StyledButton color={'primary'} onClick={() => setOpen(true)} variant="contained" disableElevation>
              Request Custom List
            </StyledButton>
          </CardContent>
        </NewFilterCard>
      </NewFilterContainer>
      <StyledDialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <DialogHeader>How would you like to filter your institution&apos;s user list?</DialogHeader>
        </DialogTitle>
        <DialogContent>
          <StyledTextField
            id="outlined-multiline-static"
            multiline
            rows="7"
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: { padding: '20px', color: colors.blue2 },
            }}
            value={text}
            onChange={event => setText(event.target.value)}
          />
        </DialogContent>
        <StyledDialogActions>
          <Grid container justifyContent="space-between" direction="row" alignItems="center" alignContent="center">
            <Grid item style={{ width: '55%' }}>
              <StyledButton color={'primary'} onClick={handleClose} variant="contained" disableElevation>
                Submit Request
              </StyledButton>
            </Grid>
            <Grid item style={{ width: '45%' }}>
              <CancelButton onClick={handleClose} color="inherit" disableElevation>
                <DisabledText>Cancel Request</DisabledText>
              </CancelButton>
            </Grid>
          </Grid>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
};

export { CustomFilterCard };
