import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import { Label, StyledExpansionPanel, SectionTitle, SubLabel, SectionContainer } from '../styledComponents';
import { colors } from '../../constants/colors';
import { InvestmentProfile } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';
import { isObjectEmpty } from '../../constants/utils';

const GridBorderTop = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 20px 5px;
`;

const StyledGrid = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 20px 5px;
  width: 45%;
`;

const GridLabel = styled(Grid)`
  width: 70%;
`;

interface InvestmentProfileProps {
  investmentProfile: InvestmentProfile;
}

const InvestmentProfileSection: React.FunctionComponent<InvestmentProfileProps> = ({ investmentProfile }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Investment Profile</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        {investmentProfile && !isObjectEmpty(investmentProfile) ? (
          <SectionContainer container direction="column" justifyContent="space-between">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <GridLabel item>
                    <Label>Has investment accounts?</Label>
                  </GridLabel>
                  <Grid item>
                    <SubLabel>
                      {investmentProfile.investment_accounts
                        ? investmentProfile.investment_accounts.length > 0
                          ? 'Yes'
                          : 'No'
                        : 'N/A'}
                    </SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <GridLabel item>
                    <Label>Investment Experience</Label>
                  </GridLabel>
                  <Grid item>
                    <SubLabel>{investmentProfile.years_of_investment_experience}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <GridLabel item>
                    <Label>Has financial planner?</Label>
                  </GridLabel>
                  <Grid item>
                    <SubLabel>{investmentProfile.has_financial_planner}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
              <StyledGrid item>
                <Grid item>
                  <Label>Suggested Risk Tolerance</Label>
                  <SubLabel>{investmentProfile.suggested_risk_tolerance}</SubLabel>
                </Grid>
              </StyledGrid>
              <StyledGrid item>
                <Grid item>
                  <Label>Current Asset Allocation</Label>
                  <SubLabel>{investmentProfile.investment_allocation_estimate}</SubLabel>
                </Grid>
              </StyledGrid>
            </Grid>
          </SectionContainer>
        ) : (
          <SectionContainer container direction="column" justifyContent="space-between">
            <EmptySectionMessage message={'hasn’t completed the ‘Investments’ section yet.'} />
          </SectionContainer>
        )}
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { InvestmentProfileSection };
