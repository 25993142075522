import { AppThunkAction } from './../';
import { REQUEST_USER_SUMMARY, SEND_REQUEST, REQUEST_USER_PDF } from './actionTypes';
import api from '../../services/adminApi';
import { UserSummary, UserPDF } from '../../models/UserSummary';

interface RequestUserSummaryAction {
  type: typeof REQUEST_USER_SUMMARY;
  payload: UserSummary;
}

interface SendRequestAction {
  type: typeof SEND_REQUEST;
}

interface RequestPDFAction {
  type: typeof REQUEST_USER_PDF;
  payload: UserPDF;
}

export type UserPDFAction = RequestPDFAction | SendRequestAction;

export type UserSummaryAction = RequestUserSummaryAction | SendRequestAction;

export const UserSummaryActionCreators = {
  requestUserSummary: (path: string): AppThunkAction<UserSummaryAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.dashboard) {
      try {
        api.defaults.headers.Authorization = localStorage.getItem('token');
        dispatch({
          type: SEND_REQUEST,
        });
        await api.get(path).then(response => {
          dispatch({
            type: REQUEST_USER_SUMMARY,
            payload: response.data,
          });
        });
      } catch (err) {
        console.log(err);
      }
    }
  },
  getPDF: (path: string): AppThunkAction<UserPDFAction> => async (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.dashboard) {
      try {
        api.defaults.headers.Authorization = localStorage.getItem('token');
        dispatch({
          type: SEND_REQUEST,
        });
        await api.get(path).then(response => {
          dispatch({
            type: REQUEST_USER_PDF,
            payload: response.data,
          })
         window.open(response.data.url, "_blank");
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
};
