import React from 'react';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';

import history from '../services/history';

import configureStore from '../store/configureStore';
import LogInPage from '../pages/loginPage';
import ForgotPassword from "../pages/forgotPasswordPage";
import DashboardPage from '../pages/dashboardPage';
import UserDetailsPage from '../pages/userDetailsPage';
import LoggedRoute from './loggedRoute';

const store = configureStore(history);

const Pages = () => {
    const props = {
        history: history
    }
    return (
        <Provider store={store}>
            <ConnectedRouter {...props}>
                <Switch>
                    <Route path={'/'} exact component={LogInPage}/>
                    <Route path={'/forgotpassword'} exact component={ForgotPassword}/>
                    <LoggedRoute path={'/dashboard'} exact component={DashboardPage}/>
                    <LoggedRoute path={'/user/:userId/summary_data'} exact component={UserDetailsPage}/>
                    <Route
                        path={'/signout'}
                        exact
                        render={() => {
                            history.push('/');
                            localStorage.clear();
                            return null;
                        }}
                    />
                </Switch>
            </ConnectedRouter>
        </Provider>
    );
};

export default Pages;
