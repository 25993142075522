export const usStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const badgeAwarded = [
    'Setup Badge',
    'Snapshot Badge',
    'Budget Badge',
    'Debt Badge',
    'Insurance Badge',
    'Retirement Badge',
    'Investment Badge',
    'Tax Planning Badge',
    'Estate and Wills Badge',
    'Education Badge',
    'New Account Badge',
    'New To-Do Badge',
    'To-Do Complete Badge',
    'Profile Badge',
    'Article Badge',
    'Flashcard Badge',
    'Linked Account Badge',
    'Calculator Badge',
];

export const initialFiltersState = {
    states: [],
    badges: [],
    institution: "0",
    hasEmergencyFund: null,
    hasStarterEmergencyFund: null,
    hasChildren: null,
    hasDependents: null,
    isHomeowner: null,
    isVehicleOwner: null,
    minTodos: null,
    maxTodos: null,
    todoByName: null,
    minAssets: null,
    maxAssets: null,
    minDebt: null,
    maxDebt: null,
    minNetWorth: null,
    maxNetWorth: null,
    minIncome: null,
    maxIncome: null,
    minExpenses: null,
    maxExpenses: null,
    minSurplus: null,
    maxSurplus: null,
    minShortage: null,
    maxShortage: null,
    minCreditCardDebt: null,
    maxCreditCardDebt: null,
    minStudentLoanDebt: null,
    maxStudentLoanDebt: null,
    minMortgageDebt: null,
    maxMortgageDebt: null,
    minAutoLoanDebt: null,
    maxAutoLoanDebt: null,
    minPersonalLoanDebt: null,
    maxPersonalLoanDebt: null,
    needsLifeInsurance: null,
    needsDisabilityCoverage: null,
    needsUmbrellaCoverage: null,
    needsReviewInsurance: null,
    hasWills: null,
    hasGuardianship: null,
    needsReviewEstate: null,
    hasSavingsPlans: null,
    minTotalSavings: null,
    maxTotalSavings: null,
    minMonthlySavings: null,
    maxMonthlySavings: null,
    isMoverShaker: null,
    isSocialButterfly: null,
    isSteadyEddy: null,
    isPerfectionist: null,
    isRetirementSaver: null,
    hasRetirementSavingsAcct: null,
    isActiveInvestor: null,
    hasAlternativeInvestmentIncome: null,
    needsPortfolioReview: null,
    isWorkingWithPlanner: null,
    maxAvgRate: null,
    minAvgRate: null,
    hasMunicipalBonds: null,
    hasTaxableBonds: null,
    alternativeMinTax: null,
};
