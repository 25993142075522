export function isObjectEmpty(obj: Object) {
  return Object.getOwnPropertyNames(obj).length < 1;
}

// Create our number formatter.
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const prepareValue = (value: string) => {
  let str = value.replace('$', '');
  str = str.replace(',', '');
  return parseFloat(str);
};

export const prepareValueRound = (value: string) => {
  let rnum = Math.round(Number(value));
  //str = str.replace(',', '');
  //return parseFloat(str);
  return rnum;
};


