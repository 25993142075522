import { useEffect } from 'react';
import * as React from 'react';
import { FilterContainer, FilterInput, FilterItem, Label } from '../styledComponents';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import { BaseFilter } from './baseFilter';
import InputAdornment from '@mui/material/InputAdornment';
import { usePrevious } from '../../hooks/usePrevious';

interface CollegeSavingsFilterProps {
  onEnter: any;
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

const CollegeSavingsFilter: React.FunctionComponent<CollegeSavingsFilterProps> = ({
  onEnter,
  onClickButton,
  filters,
  setFiltersState,
}) => {
  const prevHasSavingsPlans = usePrevious(String(filters.hasSavingsPlans)) || null;

  useEffect(() => {
    if (String(prevHasSavingsPlans) !== String(filters.hasSavingsPlans)) {
      onClickButton();
    }
  }, [prevHasSavingsPlans, filters, onClickButton]);

  return (
    <BaseFilter title="College Savings Plans">
      <Grid container direction="column" justifyContent="space-between">
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>529 Savings Plans</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasSavingsPlans === true}
                onClick={() => {
                  filters.hasSavingsPlans !== true
                    ? setFiltersState({ hasSavingsPlans: true })
                    : setFiltersState({ hasSavingsPlans: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasSavingsPlans === false}
                onClick={() => {
                  filters.hasSavingsPlans !== false
                    ? setFiltersState({ hasSavingsPlans: false })
                    : setFiltersState({ hasSavingsPlans: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        {/* need to get better data from db */}
        {/*<FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Total Savings</Label>
            <FilterInput
              id="minTotalSavings"
              placeholder="0"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.minTotalSavings || ''}
              onChange={e => setFiltersState({ minTotalSavings: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Total Savings</Label>
            <FilterInput
              id="maxTotalSavings"
              placeholder="20,000"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.maxTotalSavings || ''}
              onChange={e => setFiltersState({ maxTotalSavings: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Monthly Savings Needed</Label>
            <FilterInput
              id="minMonthlySavings"
              placeholder="0"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.minMonthlySavings || ''}
              onChange={e => setFiltersState({ minMonthlySavings: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Monthly Savings Needed</Label>
            <FilterInput
              id="maxMonthlySavings"
              placeholder="20,000"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.maxMonthlySavings || ''}
              onChange={e => setFiltersState({ maxMonthlySavings: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
        */}
      </Grid>
    </BaseFilter>
  );
};

export { CollegeSavingsFilter };
