import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { StyledExpansionPanel, SectionTitle, SubLabel, SectionContainer, GridBorderTop } from '../styledComponents';
import { colors } from '../../constants/colors';
import { Todo } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';

interface TodoListProps {
  todoList: [Todo];
}

const TodoListSection: React.FunctionComponent<TodoListProps> = ({ todoList }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>To-do List</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <SectionContainer container direction="column" justifyContent="space-between">
          {todoList && todoList.length > 0 ? (
            todoList.map((todo, index) => (
              <GridBorderTop item key={index}>
                <SubLabel>{todo.name}</SubLabel>
              </GridBorderTop>
            ))
          ) : (
            <EmptySectionMessage message={'doesn’t have any to-do’s yet.'} />
          )}
        </SectionContainer>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { TodoListSection };
