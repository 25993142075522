import { useEffect } from 'react';
import * as React from 'react';
import {Grid, SelectChangeEvent} from '@mui/material';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import { FiltersButton } from '../filtersButton';
import { Label, FilterContainer, FilterItem, FilterFormControl, StyledMenuItem } from '../styledComponents';
import { colors } from '../../constants/colors';
import { usStates } from '../../constants/constants';
import { BaseFilter } from './baseFilter';
import { usePrevious } from '../../hooks/usePrevious';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 539,
      width: 250,
    },
  },
};

interface UserTraitsProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

const UserTraitsFilter: React.FunctionComponent<UserTraitsProps> = ({ onClickButton, filters, setFiltersState }) => {
  const handleChangeStates = (event: SelectChangeEvent<string[]>) => {
    setFiltersState({ states: event.target.value as string[] });
  };
  const statesLength = usePrevious(filters.states.length) || 0;
  const prevHasChildren = usePrevious(String(filters.hasChildren)) || null;
  const prevHasDependents = usePrevious(String(filters.hasDependents)) || null;
  const prevHasEmergencyFund = usePrevious(String(filters.hasEmergencyFund)) || null;
  const prevHasStarterEmergencyFund = usePrevious(String(filters.hasStarterEmergencyFund)) || null;
  const prevIsHomeOwner = usePrevious(String(filters.isHomeowner)) || null;
  const prevIsVehicleOwner = usePrevious(String(filters.isVehicleOwner)) || null;


  useEffect(() => {
    if (
      statesLength !== filters.states.length ||
      String(prevHasChildren) !== String(filters.hasChildren) ||
      String(prevHasDependents) !== String(filters.hasDependents) ||
      String(prevHasEmergencyFund) !== String(filters.hasEmergencyFund) ||
      String(prevHasStarterEmergencyFund) !== String(filters.hasStarterEmergencyFund) ||
      String(prevIsHomeOwner) !== String(filters.isHomeowner) ||
      String(prevIsVehicleOwner) !== String(filters.isVehicleOwner)
    ) {
      onClickButton();
    }
  }, [
    statesLength,
    filters,
    prevHasChildren,
    prevHasDependents,
    prevHasEmergencyFund,
    prevHasStarterEmergencyFund,
    prevIsHomeOwner,
    prevIsVehicleOwner,
    onClickButton,
  ]);

  return (
    <BaseFilter title={'User Traits'}>
      <Grid container direction="row" justifyContent="flex-start">
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>State of Residence</Label>
          </Grid>
          <Grid item>
            <FilterFormControl variant="outlined">
              <InputLabel id="demo-mutiple-checkbox-label">Select States</InputLabel>
              <Select
                id="demo-mutiple-checkbox"
                multiple
                value={filters.states}
                onChange={handleChangeStates}
                input={<Input />}
                renderValue={ selected => { if (selected.length === 1) { return selected} else { return "Multiple States" }}}
                MenuProps={MenuProps}
                IconComponent={ExpandMoreIcon}
              >
                {usStates.map(name => (
                  <StyledMenuItem key={name} value={name}>
                    <Checkbox
                      checked={filters.states.indexOf(name) > -1}
                      color="primary"
                      style={{ color: colors.blue1 }}
                    />
                    <ListItemText primary={name} color={colors.blue2} />
                  </StyledMenuItem>
                ))}
              </Select>
            </FilterFormControl>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Children</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasChildren === true}
                onClick={() => {
                  filters.hasChildren !== true
                    ? setFiltersState({ hasChildren: true })
                    : setFiltersState({ hasChildren: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasChildren === false}
                onClick={() => {
                  filters.hasChildren === false
                    ? setFiltersState({ hasChildren: null })
                    : setFiltersState({ hasChildren: false });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Dependents</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasDependents === true}
                onClick={() => {
                  filters.hasDependents !== true
                    ? setFiltersState({ hasDependents: true })
                    : setFiltersState({ hasDependents: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasDependents === false}
                onClick={() => {
                  filters.hasDependents !== false
                    ? setFiltersState({ hasDependents: false })
                    : setFiltersState({ hasDependents: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="flex-start">
          <Grid item>
            <Label>Emergency Fund: 3 - 6 Months Expenses</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasEmergencyFund === true}
                onClick={() => {
                  filters.hasEmergencyFund !== true
                    ? setFiltersState({ hasEmergencyFund: true })
                    : setFiltersState({ hasEmergencyFund: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasEmergencyFund === false}
                onClick={() => {
                  filters.hasEmergencyFund !== false
                    ? setFiltersState({ hasEmergencyFund: false })
                    : setFiltersState({ hasEmergencyFund: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid item>
            <Label>Starter Emergency Fund: $500 - $1,000</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasStarterEmergencyFund === true}
                onClick={() => {
                  filters.hasStarterEmergencyFund !== true
                    ? setFiltersState({ hasStarterEmergencyFund: true })
                    : setFiltersState({ hasStarterEmergencyFund: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasStarterEmergencyFund === false}
                onClick={() => {
                  filters.hasStarterEmergencyFund !== false
                    ? setFiltersState({ hasStarterEmergencyFund: false })
                    : setFiltersState({ hasStarterEmergencyFund: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Homeowner</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isHomeowner === true}
                onClick={() => {
                  filters.isHomeowner !== true
                    ? setFiltersState({ isHomeowner: true })
                    : setFiltersState({ isHomeowner: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isHomeowner === false}
                onClick={() => {
                  filters.isHomeowner !== false
                    ? setFiltersState({ isHomeowner: false })
                    : setFiltersState({ isHomeowner: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Vehicle Owner</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isVehicleOwner === true}
                onClick={() => {
                  filters.isVehicleOwner !== true
                    ? setFiltersState({ isVehicleOwner: true })
                    : setFiltersState({ isVehicleOwner: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isVehicleOwner === false}
                onClick={() => {
                  filters.isVehicleOwner !== false
                    ? setFiltersState({ isVehicleOwner: false })
                    : setFiltersState({ isVehicleOwner: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { UserTraitsFilter };
