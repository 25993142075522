
const envBase = () => {
    switch (process.env.REACT_APP_STAGE) {
        case 'dev':
            // replace this with the environment of your choosing
            return 'https://joel-dev.pocketnestapp.com';
        case 'preprod':
            return 'https://pn-preprod-lb.pocketnest.com'
        case 'prod':
            return 'https://pn-production-lb.pocketnest.com';
        case 'ffw':
            return 'https://ffw-staging.pocketnest.com';
        case 'sail':
            return 'http://localhost:80';
        case 'tdc':
            return 'https://tdc-production.pocketnest.com';
        case 'local':
            return 'http://localhost:8000';
        default:
            return 'http://localhost:8000';
    }
}
export default envBase();