import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import history from '../services/history';
import { ApplicationState } from '../store';
import BasePage from '../components/basePage';

interface IProps {
  exact?: boolean;
  signed?: boolean;
  path: string;
  component: React.ComponentType<any>;
}

const LoggedRoute = ({ component: Component, ...otherProps }: IProps) => {
  const token = localStorage.getItem('token');
  if (!token) {
    history.push('/');
  }
  return <Route render={() => <BasePage pageContent={<Component {...otherProps} />} />} />;
};

export default connect(
  (state: ApplicationState) => state.auth,
  null,
)(LoggedRoute);
