import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  Label,
  StyledExpansionPanel,
  SectionTitle,
  SubLabel,
  SectionContainer,
  GridBorderTop,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { Overview } from '../../models/UserSummary';
import {MailTo} from "../../models/MailTo";

interface OverviewProps {
  username: string;
  age: string;
  overview: Overview;
}

function MailLink({ email, subject, body, username }: MailTo) {
  return (
      <SubLabel>
        <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}  >
          {username}
        </a>
      </SubLabel>
  );
}

const UserOverviewSection: React.FunctionComponent<OverviewProps> = ({ username, age, overview }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Overview</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <SectionContainer container direction="column" justifyContent="space-between">
          <GridBorderTop container direction="column" justifyContent="space-between">
            <Grid item>
              <Label>{username}</Label>
            </Grid>
            <Grid item>
              <SubLabel>{age}</SubLabel>
            </Grid>
            <Grid item>
              <MailLink email={overview.email} subject="{ insert subject here }" body="{ insert email body here }" username={overview.email} />
            </Grid>
          </GridBorderTop>
          <GridBorderTop container direction="column" justifyContent="space-between" alignItems="flex-start">
            <SubLabel>{overview.address ? overview.address : 'N/A'}</SubLabel>
          </GridBorderTop>
        </SectionContainer>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { UserOverviewSection };
