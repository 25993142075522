import axios from 'axios';
import envBase from "./envService";

const defineEnv = () => {
  return envBase + '/api/v1/admin';
};

const adminApi = axios.create({
  baseURL: defineEnv()
});

export const baseAPI = axios.create({
  baseURL: envBase
})

export default adminApi;

