import {useEffect, useReducer} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import {UserTraitsFilter} from './userTraitsFilter';
import {NetWorthFilter} from './netWorthFilter';
import {EstimatedBudgetFilter} from './estimatedBudgetFilter';
//import { CashFlowFilter } from './cashFlowFilter';
// import {DebtFilter} from './debtFilter';
import {InsuranceFilter} from './insuranceFilter';
import {EstatePlanningFilter} from './estatePlanningFilter';
import {CollegeSavingsFilter} from './collegeSavingsFilter';
import {TaxProfileFilter} from './taxProfileFilter';
import {DashboardActionCreators, DashboardState} from '../../store/dashboard';
import {ApplicationState} from '../../store';
import {colors} from '../../constants/colors';
import {initialFiltersState} from '../../constants/constants';
import {InvestmentFilter} from './InvestmentFilter';
import {RetirementFilter} from './retirementFilter';
import {BadgesFilter} from "./badgesFilter";
import {BehavioralFilter} from './behavioralFilters';
import {ToDoFilter} from "./todoFilter";
import {InstitutionFilter} from "./institutionFilter";

const StyledList = styled(List)`
  && {
    padding: 12px 12px;
    text-align: center;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: #ffffff;
    background-color: ${colors.blue4};
    border-radius: 11px;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 6px;
    width: 70%;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
  }
`;

type OwnProps = DashboardState & typeof DashboardActionCreators;

//TODO Connect remaining filters
const UserFilters: React.FunctionComponent<OwnProps> = ({updateFilters}) => {

    const reducer = (prevState: any, value: any): any => ({
        ...prevState,
        ...value,
    });

    const [filtersState, setFiltersState] = useReducer(reducer, initialFiltersState);

    useEffect(() => {
        updateFilters(filters);
    }, [filtersState, updateFilters])

    const filters = {
        ...filtersState,
        states: filtersState.states && filtersState.states.length > 0 ? filtersState.states : null,
        badges: filtersState.badges && filtersState.badges.length > 0 ? filtersState.badges : null,
        institution: filtersState.institution !== "" ? filtersState.institution : "0",
        minTodos: filtersState.maxTodos && !filtersState.minTodos ? 0 : filtersState.minTodos,
        maxTodos: filtersState.minTodos && !filtersState.maxTodos ? 50 : filtersState.maxTodos,
        todoByName: filtersState.todoByName !== "" ? filtersState.todoByName : null,
        minAssets: filtersState.maxAssets && !filtersState.minAssets ? 0 : filtersState.minAssets,
        maxAssets: filtersState.minAssets && !filtersState.maxAssets ? 5000000 : filtersState.maxAssets,
        minDebt: filtersState.maxDebt && !filtersState.minDebt ? 0 : filtersState.minDebt,
        maxDebt: filtersState.minDebt && !filtersState.maxDebt ? 1000000 : filtersState.maxDebt,
        minNetWorth: filtersState.maxNetWorth && !filtersState.minNetWorth ? -2500000 : filtersState.minNetWorth,
        maxNetWorth: filtersState.minNetWorth && !filtersState.maxNetWorth ? 5000000 : filtersState.maxNetWorth,
        minIncome: filtersState.maxIncome && !filtersState.minIncome ? -120000 : filtersState.minIncome,
        maxIncome: filtersState.minIncome && !filtersState.maxIncome ? 5000000 : filtersState.maxIncome,
        minExpenses: filtersState.maxExpenses && !filtersState.minExpenses ? 0 : filtersState.minExpenses,
        maxExpenses: filtersState.minExpenses && !filtersState.maxExpenses ? 1000000 : filtersState.maxExpenses,
        minSurplus: filtersState.maxSurplus && !filtersState.minSurplus ? 0 : filtersState.minSurplus,
        maxSurplus: filtersState.minSurplus && !filtersState.maxSurplus ? 2000 : filtersState.maxSurplus,
        minShortage: filtersState.maxShortage && !filtersState.minShortage ? 0 : filtersState.minShortage,
        maxShortage: filtersState.minShortage && !filtersState.maxShortage ? 2000 : filtersState.maxShortage,
        minCreditCardDebt:
            filtersState.maxCreditCardDebt && !filtersState.minCreditCardDebt ? 0 : filtersState.minCreditCardDebt,
        maxCreditCardDebt:
            filtersState.minCreditCardDebt && !filtersState.maxCreditCardDebt ? 500000 : filtersState.maxCreditCardDebt,
        minStudentLoanDebt:
            filtersState.maxStudentLoanDebt && !filtersState.minStudentLoanDebt ? 0 : filtersState.minStudentLoanDebt,
        maxStudentLoanDebt:
            filtersState.minStudentLoanDebt && !filtersState.maxStudentLoanDebt ? 500000 : filtersState.maxStudentLoanDebt,
        minMortgageDebt: filtersState.maxMortgageDebt && !filtersState.minMortgageDebt ? 0 : filtersState.minMortgageDebt,
        maxMortgageDebt:
            filtersState.minMortgageDebt && !filtersState.maxMortgageDebt ? 500000 : filtersState.maxMortgageDebt,
        minAutoLoanDebt: filtersState.maxAutoLoanDebt && !filtersState.minAutoLoanDebt ? 0 : filtersState.minAutoLoanDebt,
        maxAutoLoanDebt:
            filtersState.minAutoLoanDebt && !filtersState.maxAutoLoanDebt ? 500000 : filtersState.maxAutoLoanDebt,
        minPersonalLoanDebt:
            filtersState.maxPersonalLoanDebt && !filtersState.minPersonalLoanDebt ? 0 : filtersState.minPersonalLoanDebt,
        maxPersonalLoanDebt:
            filtersState.minPersonalLoanDebt && !filtersState.maxPersonalLoanDebt ? 500000 : filtersState.maxPersonalLoanDebt,
        minTotalSavings: filtersState.maxTotalSavings && !filtersState.minTotalSavings ? 0 : filtersState.minTotalSavings,
        maxTotalSavings: filtersState.minTotalSavings && !filtersState.maxTotalSavings ? 0 : filtersState.maxTotalSavings,
        minMonthlySavings:
            filtersState.maxMonthlySavings && !filtersState.minMonthlySavings ? 0 : filtersState.minMonthlySavings,
        maxMonthlySavings:
            filtersState.minMonthlySavings && !filtersState.maxMonthlySavings ? 0 : filtersState.maxMonthlySavings,
    };

    const onFilter = () => {
        updateFilters(filters);
    };

    const onEnter = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === 'Enter') {
            onFilter();
        }
    };

    const onReset = () => {
        setFiltersState(initialFiltersState);
    };

    return (
        <StyledList>
            <StyledButton color={'primary'} onClick={onReset} variant="contained" disableElevation>
                Reset Filters
            </StyledButton>
            <BehavioralFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <InstitutionFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <UserTraitsFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <BadgesFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <ToDoFilter onEnter={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <NetWorthFilter onEnter={onEnter} filters={filtersState} setFiltersState={setFiltersState}/>
            <EstimatedBudgetFilter onEnter={onEnter} filters={filtersState} setFiltersState={setFiltersState}/>
            {/* no quality data at this time */}
            {/*<DebtFilter onEnter={onEnter} filters={filtersState} setFiltersState={setFiltersState}/>*/}
            <InsuranceFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <RetirementFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <InvestmentFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <EstatePlanningFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
            <CollegeSavingsFilter onEnter={onEnter} onClickButton={onFilter} filters={filtersState}
                                  setFiltersState={setFiltersState}/>
            <TaxProfileFilter onClickButton={onFilter} filters={filtersState} setFiltersState={setFiltersState}/>
        </StyledList>
    );
};

// Needs to remove {} from import e.g.: import UserFilters from './filters/userFilters'; This way export default works
export default connect(
    (state: ApplicationState) => state.dashboard,
    DashboardActionCreators,
)(UserFilters as any);
