import * as React from 'react';
import '@reach/tabs/styles.css';
import InputAdornment from '@mui/material/InputAdornment';
import { Grid } from '@mui/material';
import { BaseFilter } from './baseFilter';
import { FilterContainer, FilterItem, Label, FilterInput } from '../styledComponents';



interface EstimatedBudgetFilterProps {
  onEnter: any;
  filters: any;
  setFiltersState: any;
}

// TODO Needs to enable Min and Max EXPENSES, SURPLUS and SHORTAGE
const EstimatedBudgetFilter: React.FunctionComponent<EstimatedBudgetFilterProps> = ({
  onEnter,
  filters,
  setFiltersState,
}) => {


  return (
    <BaseFilter title="Estimated Budget">
      <Grid container direction="column" justifyContent="space-between">
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Yr. Income</Label>
            <FilterInput
              id="minIncome"
              placeholder="0"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.minIncome || ''}
              onChange={e => setFiltersState({ minIncome: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Yr. Income</Label>
            <FilterInput
              id="maxIncome"
              placeholder="1,000,000"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.maxIncome || ''}
              onChange={e => setFiltersState({ maxIncome: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number"
            />
          </FilterItem>
        </FilterContainer>
{/* Values not currently stored in DB */}
{/*
        <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Min Expenses</Label>
            <FilterInput
              id="minExpenses"
              placeholder="0"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.minExpenses || ''}
              onChange={e => setFiltersState({ minExpenses: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number" disabled
            />
          </FilterItem>
          <FilterItem item>
            <Label>Max Expenses</Label>
            <FilterInput
              id="maxExpenses"
              placeholder="1,000,000"
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              variant="outlined"
              value={filters.maxExpenses || ''}
              onChange={e => setFiltersState({ maxExpenses: parseFloat(e.target.value) })}
              onKeyPress={ev => onEnter(ev)}
              type="number" disabled
            />
          </FilterItem>
        </FilterContainer>

        <FilterContainer container>
          <Tabs onChange={index => setTabIndex(index)}>
            <StyledTabList>
              <Tab as={StyledButton}>
                <Label>Surplus</Label>
              </Tab>
              <Tab as={StyledButton}>
                <Label>Shortage</Label>
              </Tab>
            </StyledTabList>
            <TabPanels as={PanelWrapper} tabIndex={tabIndex}>
              <TabPanel>
                <Grid container direction="row" justify="space-between">
                  <TabItem item>
                    <Label>Min Surplus</Label>
                    <FilterInput
                      id="minSurplus"
                      placeholder="0"
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                      value={filters.minSurplus || ''}
                      onChange={e => setFiltersState({ minSurplus: parseFloat(e.target.value) })}
                      onKeyPress={ev => onEnter(ev)}
                      type="number"
                      style={{ backgroundColor: colors.gray3 }}
                      disabled
                    />
                  </TabItem>
                  <TabItem item>
                    <Label>Max Surplus</Label>
                    <FilterInput
                      id="maxSurplus"
                      placeholder="2,000"
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                      value={filters.maxSurplus || ''}
                      onChange={e => setFiltersState({ maxSurplus: parseFloat(e.target.value) })}
                      onKeyPress={ev => onEnter(ev)}
                      type="number"
                      style={{ backgroundColor: colors.gray3 }}
                      disabled
                    />
                  </TabItem>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid container direction="row" justify="space-between">
                  <TabItem item>
                    <Label>Min Shortage</Label>
                    <FilterInput
                      id="minShortage"
                      placeholder="0"
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                      value={filters.minShortage || ''}
                      onChange={e => setFiltersState({ minShortage: parseFloat(e.target.value) })}
                      onKeyPress={ev => onEnter(ev)}
                      type="number"
                      style={{ backgroundColor: colors.gray3 }}
                      disabled
                    />
                  </TabItem>
                  <TabItem item>
                    <Label>Max Shortage</Label>
                    <FilterInput
                      id="maxShortage"
                      placeholder="2,000"
                      InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      variant="outlined"
                      value={filters.maxShortage || ''}
                      onChange={e => setFiltersState({ maxShortage: parseFloat(e.target.value) })}
                      onKeyPress={ev => onEnter(ev)}
                      type="number"
                      style={{ backgroundColor: colors.gray3 }}
                      disabled
                    />
                  </TabItem>
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </FilterContainer>
*/}
      </Grid>
    </BaseFilter>
  );
};

export { EstimatedBudgetFilter };
