import { useEffect } from 'react';
import * as React from 'react';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import { Label, FilterContainer, FilterItem } from '../styledComponents';
import { BaseFilter } from './baseFilter';
import { usePrevious } from '../../hooks/usePrevious';

interface InvestmentTraitsProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

//TODO Connect remaining filters
const InvestmentFilter: React.FunctionComponent<InvestmentTraitsProps> = ({ onClickButton, filters, setFiltersState }) => {

  const prevActiveInvestor = usePrevious(String(filters.isActiveInvestor)) || null;
  const prevHasAlternativeInvestmentIncome = usePrevious(String(filters.hasAlternativeInvestmentIncome)) || null;
  const prevNeedsPortfolioReview = usePrevious(String(filters.needsPortfolioReview)) || null;
  const prevWorkingWithPlanner = usePrevious(String(filters.isWorkingWithPlanner)) || null;

  useEffect(() => {
    if (
      String(prevActiveInvestor) !== String(filters.isActiveInvestor) ||
      String(prevHasAlternativeInvestmentIncome) !== String(filters.hasAlternativeInvestmentIncome) ||
      String(prevNeedsPortfolioReview) !== String(filters.needsPortfolioReview) ||
      String(prevWorkingWithPlanner) !== String(filters.isWorkingWithPlanner)
    ) {
      onClickButton();
    }
  }, [
    filters, 
    prevActiveInvestor, 
    prevHasAlternativeInvestmentIncome, 
    prevNeedsPortfolioReview, 
    prevWorkingWithPlanner, 
    onClickButton,
  ]);

  return (
    <BaseFilter title={'Investments'}>
      <Grid container direction="row" justifyContent="flex-start">
      <FilterContainer container direction="row" justifyContent="space-between">
          <FilterItem item>
            <Label>Active Investor</Label>
          </FilterItem>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isActiveInvestor === true}
                onClick={() => {
                  filters.isActiveInvestor !== true
                    ? setFiltersState({ isActiveInvestor: true })
                    : setFiltersState({ isActiveInvestor: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isActiveInvestor === false}
                onClick={() => {
                  filters.isHomeowner !== false
                    ? setFiltersState({ isActiveInvestor: false })
                    : setFiltersState({ isActiveInvestor: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="flex-start">
          <Grid item>
            <Label>Alternative Investment Income</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasAlternativeInvestmentIncome === true}
                onClick={() => {
                  filters.hasAlternativeInvestmentIncome !== true
                    ? setFiltersState({ hasAlternativeInvestmentIncome: true })
                    : setFiltersState({ hasAlternativeInvestmentIncome: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasAlternativeInvestmentIncome === false}
                onClick={() => {
                  filters.hasAlternativeInvestmentIncome === false
                    ? setFiltersState({ hasAlternativeInvestmentIncome: null })
                    : setFiltersState({ hasAlternativeInvestmentIncome: false });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid item>
            <Label>Needs Portfolio Review</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsPortfolioReview === true}
                onClick={() => {
                  filters.needsPortfolioReview !== true
                    ? setFiltersState({ needsPortfolioReview: true })
                    : setFiltersState({ needsPortfolioReview: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsPortfolioReview === false}
                onClick={() => {
                  filters.needsPortfolioReview !== false
                    ? setFiltersState({ needsPortfolioReview: false })
                    : setFiltersState({ needsPortfolioReview: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="row" justifyContent="space-between">
          <Grid item>
            <Label>Working with Financial Planner</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isWorkingWithPlanner === true}
                onClick={() => {
                  filters.isWorkingWithPlanner !== true
                    ? setFiltersState({ isWorkingWithPlanner: true })
                    : setFiltersState({ isWorkingWithPlanner: null });
                }}
              >
                Yes
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.isWorkingWithPlanner === false}
                onClick={() => {
                  filters.isWorkingWithPlanner !== false
                    ? setFiltersState({ isWorkingWithPlanner: false })
                    : setFiltersState({ isWorkingWithPlanner: null });
                }}
              >
                No
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { InvestmentFilter };
