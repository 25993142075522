import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  GridNoBorder,
  Label,
  StyledExpansionPanel,
  SectionTitle,
  SubLabel,
  SectionContainer,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { IncomeTax } from '../../models/UserSummary';
import { isObjectEmpty } from '../../constants/utils';
import EmptySectionMessage from './emptySectionMessage';

const P1 = styled.p`
  margin: 0 0 0 20px;
  color: ${colors.blue1};
  font-weight: bold;
  font-size: 34px;
`;

const VerticalLine = styled.hr`
  width: 0;
  height: 30px;
  margin: 0 20px;
`;

const GridBorderTop = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 20px 5px;
  width: 45%;
`;

const StyledGrid = styled(Grid)`
  width: 45%;
`;

interface EstimatedBudgetProps {
  taxes: IncomeTax;
}

const TaxesSection: React.FunctionComponent<EstimatedBudgetProps> = ({ taxes }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Taxes</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        {taxes && !isObjectEmpty(taxes) ? (
          <SectionContainer container direction="column" justifyContent="space-between">
            <GridNoBorder container direction="row" justifyContent="space-between" alignItems="center">
              <StyledGrid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Label>Marginal Tax Rate</Label>
                  </Grid>
                  <Grid item>
                    <P1>{taxes.marginal_tax_rate}</P1>
                  </Grid>
                </Grid>
              </StyledGrid>
              <VerticalLine color={colors.gray2} />
              <StyledGrid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Label>Average Tax Rate</Label>
                  </Grid>
                  <Grid item>
                    <P1>{taxes.average_tax_rate}</P1>
                  </Grid>
                </Grid>
              </StyledGrid>
            </GridNoBorder>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Municipal Bonds</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{taxes.municipal_bonds}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Taxable Bonds</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{taxes.taxable_bonds}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
            </Grid>
          </SectionContainer>
        ) : (
          <SectionContainer container direction="column" justifyContent="space-between">
            <EmptySectionMessage message={'hasn’t completed the ‘Tax Planning’ section yet.'} />
          </SectionContainer>
        )}
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { TaxesSection };
