import baseApi from "./baseAPI";
import history from "./history";
import adminApi from "./adminApi";
export function forgotPassword(body: object) {

    try {
        adminApi.post('forgotpassword', body)
            .then((response) => {
                // return to login page
                history.push('/');
            }, (error) => {
                console.log("Error: " + error);
            });
    }
    catch (e) {
        console.log("Error: " + e);
    }

}
