import { AppThunkAction } from './../';
import { SIGN_FAILURE, SIGN_IN_REQUEST, SIGN_IN_SUCCESS, SIGN_OUT } from './actionTypes';
import adminApi from '../../services/adminApi';
import history from '../../services/history';

interface SignInPayload {
  token: string;
  institution_id: number;
  first_name: string;
  last_name: string;
}

interface SignInRequestAction {
  type: typeof SIGN_IN_REQUEST;
}
function signInRequest(): SignInRequestAction {
  return {
    type: SIGN_IN_REQUEST,
  };
}

interface SignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: SignInPayload;
}

interface SignInFailureAction {
  type: typeof SIGN_FAILURE;
}

function signInFailure(): SignInFailureAction {
  return {
    type: SIGN_FAILURE,
  };
}

interface SignOutAction {
  type: typeof SIGN_OUT;
}

export type AuthAction = SignInRequestAction | SignInSuccessAction | SignInFailureAction | SignOutAction;

export const AuthActionCreators = {
  signIn: (body: object): AppThunkAction<AuthAction> => async (dispatch, getState) => {
    const appState = getState();

    const saveLocalInfo = (response: any) => {
      localStorage.setItem('token', `Bearer ${response.data.token}`);
      localStorage.setItem('institutionId', response.data.institution_id);
      localStorage.setItem('institutionName', response.data.institution_name);
      localStorage.setItem('email', response.data.email);
      localStorage.setItem('firstName', response.data.first_name ? response.data.first_name : '');
      localStorage.setItem('lastName', response.data.last_name ? response.data.last_name : '');
    };

    if (appState && appState.auth) {
      dispatch(signInRequest());
      try {
        await adminApi.post('login', body).then(response => {
          adminApi.defaults.headers.Authorization = `Bearer ${response.data.token}`;
          saveLocalInfo(response);
          dispatch({
            type: SIGN_IN_SUCCESS,
            payload: response.data,
          });
        });
        history.push('/dashboard');
      } catch (err) {
        dispatch(signInFailure());
      }
    }
  },
};
