import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import {
  Label,
  StyledExpansionPanel,
  SectionTitle,
  SubLabel,
  StyledGridBorderBottom,
  SectionContainer,
  GridBorderBottom,
} from '../styledComponents';
import { colors } from '../../constants/colors';
import { Debt } from '../../models/UserSummary';
import EmptySectionMessage from './emptySectionMessage';

const TitleContainer = styled(Grid)`
  background-color: ${colors.gray1};
  padding-left: 31px;
  padding-right: 35px;
`;

const GridNoBorder = styled(GridBorderBottom)`
  border-bottom: none;
  padding: 15px 5px 5px 5px;
`;

const Title = styled.h5`
  margin: 10px 0;
  font-weight: normal;
  color: ${colors.blue3};
`;

const P1 = styled.p`
  margin: 0;
  color: ${colors.blue1};
  font-weight: bold;
  font-size: 25px;
`;
const StyleP = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.blue3};
`;

interface DebtProps {
  debt: Debt;
}

const DebtSection: React.FunctionComponent<DebtProps> = ({ debt }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Debt</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {debt.debt_accounts && debt.debt_accounts.length > 0 ? (
            <>
              <SectionContainer container direction="column" justifyContent="space-between">
                <GridNoBorder container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Label>TOTAL DEBT</Label>
                  </Grid>
                  <Grid item>
                    <P1>{debt.total_debt}</P1>
                  </Grid>
                </GridNoBorder>
              </SectionContainer>
              <Grid container direction="column">
                <TitleContainer container direction="row" justifyContent="space-between">
                  <Title>DEBT BREAKDOWN</Title>
                  <StyleP>Balance</StyleP>
                </TitleContainer>
                <SectionContainer container direction="column" justifyContent="space-between">
                  {debt.debt_accounts.map((account, index) => (
                    <StyledGridBorderBottom container direction="row" justifyContent="space-between" key={index}>
                      <Grid item>
                        <SubLabel>{account.name}</SubLabel>
                      </Grid>
                      <Grid item>
                        <Label>{account.value_string}</Label>
                      </Grid>
                    </StyledGridBorderBottom>
                  ))}
                </SectionContainer>
              </Grid>
            </>
          ) : (
            <SectionContainer container direction="column" justifyContent="space-between">
              <EmptySectionMessage message={'hasn’t provided any debt information yet.'} />
            </SectionContainer>
          )}
        </Grid>
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { DebtSection };
