import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { colors } from '../constants/colors';

const FiltersButton = styled(({ active, bg, sm, ...forwardProps }) => <Button {...forwardProps} />)`
  && {
    background-color: ${({ active }) => (active ? colors.blue1 : colors.white)};
    color: ${({ active }) => (active ? colors.white : colors.blue1)};;
    width: ${({ bg, sm }) => (bg ? '200' : sm ? '96' : '125')}px;
    height: 30px;
    margin-top: 6px;
    border: 0;
    border-radius: 5px;
    text-transform: none;
    :hover {
      background-color: ${colors.gray2};
    }
    :disabled {
      background-color: ${colors.gray1};
    }
  }
  & .MuiButton-label {
    color: ${({ active }) => (active ? colors.white : colors.blue2)};
  }
`;

export { FiltersButton };
