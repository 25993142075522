import { Action, Reducer } from 'redux';
import { AuthState } from './state';
import { AuthAction } from './actions';
import { SIGN_IN_REQUEST, SIGN_FAILURE, SIGN_IN_SUCCESS, SIGN_OUT } from './actionTypes';

const initialState: AuthState = {
  token: undefined,
  institution_id: undefined,
  first_name: undefined,
  last_name: undefined,
  signed: false,
  isLoading: false,
  authError: false,
};

export const AuthReducer: Reducer<AuthState> = (state: AuthState | undefined, incomingAction: Action): AuthState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as AuthAction;
  switch (action.type) {
    case SIGN_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        institution_id: action.payload.institution_id,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
        signed: true,
        isLoading: false,
        authError: false,
      };
    case SIGN_FAILURE:
      return {
        ...state,
        isLoading: false,
        authError: true,
      };
    case SIGN_OUT:
      return {
        ...state,
        token: undefined,
        signed: false,
        authError: false,
      };
  }

  return state;
};
