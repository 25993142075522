enum colors {
  blue1 = '#0078ff',
  blue2 = '#0f2568',
  blue3 = '#5a6895',
  blue4 = '#206dfd',
  blue5 = '#0044C3',
  blue6 = '#717B9C',
  gray1 = '#eff1fa',
  gray2 = '#d7def0',
  gray3 = '#eef1fa',
  gray4 = '#b1c0df',
  red1 = '#ffd9d9',
  white = '#FFFFFF',
}

export { colors };
