import * as React from 'react';
import { Grid } from '@mui/material';
import { FilterContainer, FilterInput, FilterItem, Label } from '../styledComponents';
import { BaseFilter } from './baseFilter';
import {useEffect, useState} from "react";

interface TodoFilterProps {
    onEnter: any;
    filters: any;
    setFiltersState: any;
}


const ToDoFilter: React.FunctionComponent<TodoFilterProps> = ({ onEnter, filters, setFiltersState }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // useEffect(
    //     () => {
    //          setFiltersState({ todoByName:searchTerm })
    //     }
    // , [searchTerm, setFiltersState]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {

            setFiltersState({ todoByName:searchTerm })
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm, setFiltersState])

    return (
        <BaseFilter title="Todos">
            <Grid container direction="column" justifyContent="space-between">
                <FilterContainer container direction="row" justifyContent="space-between">
                    <FilterItem item>
                        <Label>Min Todos</Label>
                        <FilterInput
                            id="minTodos"
                            size={"small"}
                            placeholder="0"
                            variant="outlined"
                            value={filters.minTodos || ''}
                            onChange={e => setFiltersState({ minTodos: parseFloat(e.target.value) })}
                            onKeyPress={ev => onEnter(ev)}
                            type="number"
                        />
                    </FilterItem>
                    <FilterItem item>
                        <Label>Max Todos</Label>
                        <FilterInput
                            id="maxTodos"
                            size={"small"}
                            placeholder="25"
                            variant="outlined"
                            value={filters.maxTodos || ''}
                            onChange={e => setFiltersState({ maxTodos: parseFloat(e.target.value) })}
                            onKeyPress={ev => onEnter(ev)}
                            type="number"
                        />
                    </FilterItem>
                </FilterContainer>
                <Label>Search Todo by Name</Label>
                <FilterContainer>
                    <FilterInput
                        id="filterTodoByName"
                        size={"small"}
                        placeholder="Search by todo name..."
                        value={searchTerm}
                        variant="outlined"
                        // value={filters.todoByName || ''}
                        onChange={e => setSearchTerm(e.target.value)}
                        type="string"
                    />
                </FilterContainer>
            </Grid>
        </BaseFilter>
    );
};

export { ToDoFilter };
