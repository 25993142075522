import * as React from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { GridBorderBottom, GridNoBorder, Label, SummaryContainer } from '../styledComponents';
import { colors } from '../../constants/colors';
import { InvestmentProfile, UserData } from '../../models/UserSummary';

interface UserSummaryProps {
  userData: UserData;
  investmentProfile: InvestmentProfile;
}

const P1 = styled.p`
  margin: 0;
  color: ${colors.blue1};
  font-weight: bold;
  font-size: 25px;
`;

const DisabledText = styled.p`
  color: ${colors.gray4};
  margin: 4px 0 0 0;
  font-size: 18px;
  font-weight: bold;
`;

const UserSummarySection: React.FunctionComponent<UserSummaryProps> = ({ userData, investmentProfile }) => {
  return (
    <SummaryContainer container direction="column" justifyContent="space-between">
      <GridBorderBottom container direction="row" justifyContent="space-between">
        <Grid item>
          <Label>TOTAL NET WORTH</Label>
          <P1>{userData.total_net_worth_est ? userData.total_net_worth_est : 'N/A'}</P1>
        </Grid>
        <Grid item>
          <Label>CASH FLOW</Label>
          <P1>{userData.cash_flow_avg_monthly ? userData.cash_flow_avg_monthly : '--'}</P1>
        </Grid>
      </GridBorderBottom>
      <GridBorderBottom container direction="row" justifyContent="space-between">
        <Grid item>
          <Label>TOTAL DEBT</Label>
          <P1>{userData.total_debt_est ? userData.total_debt_est : 'N/A'}</P1>
        </Grid>
        <Grid item>
          <Label>DTI RATIO</Label>
          <P1>
            {userData.dti_ratio
              ? typeof userData.dti_ratio === 'number'
                ? userData.dti_ratio.toFixed(2)
                : '--'
              : '--'}
          </P1>
        </Grid>
        <Grid item>
          <Label>CREDIT SCORE</Label>
          <DisabledText>{userData.credit_score ? userData.credit_score : 'N/A'}</DisabledText>
        </Grid>
      </GridBorderBottom>
      <GridNoBorder container direction="row" justifyContent="space-between" alignItems="center">
        <Label>TOTAL INVESTABLE ASSETS</Label>
        <P1>{investmentProfile.investment_total ? investmentProfile.investment_total : 'N/A'}</P1>
      </GridNoBorder>
    </SummaryContainer>
  );
};

export { UserSummarySection };
