import * as React from 'react';
import {Grid, Select, SelectChangeEvent} from '@mui/material';
import {Label, FilterContainer, FilterFormControl, StyledMenuItem, FilterListItemText} from '../styledComponents';
import {BaseFilter} from './baseFilter';
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import {useEffect, useState} from "react";
import adminApi from '../../services/adminApi';
import {colors} from "../../constants/colors";
import {usePrevious} from "../../hooks/usePrevious";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 400,
            width: 250,
        },
    },
};

interface InstitutionFilterProps {
    onClickButton: any;
    filters: any;
    setFiltersState: any;
}

const InstitutionFilter: React.FunctionComponent<InstitutionFilterProps> = ({
                                                                                onClickButton,
                                                                                filters,
                                                                                setFiltersState
                                                                            }) => {

    const [institutions, setInstitutions] = useState<any[]>([]);

    const handleChangeInstitution = (event: SelectChangeEvent<{ value: string }>) => {
        setFiltersState({institution: event.target.value as string});
    };

    useEffect(() => {
        adminApi.defaults.headers.Authorization = localStorage.getItem('token');
        adminApi.get('institutions')
            .then((response) => setInstitutions(response.data))
            .catch(() => console.log("Error receiving institutions for user"));

    }, []);

    const prevInstitution = usePrevious(filters.institution) || "0";

    useEffect(() => {
        if (filters.institution !== prevInstitution) {
            onClickButton();
        }
    }, [
        filters,
        onClickButton,
        prevInstitution
    ]);

    // @ts-ignore
    return (
        <BaseFilter title={'Institutions'}>
            <Grid container direction="row" justifyContent="flex-start">
                <FilterContainer container direction="column" justifyContent="space-between">
                    <Grid item>
                        <Label>Institution</Label>
                    </Grid>
                    <Grid item>
                        <FilterFormControl variant="outlined">
                            <InputLabel id="institution-label">Select Institutions</InputLabel>
                            <Select
                                id="institution-select"
                                variant={'outlined'}
                                value={filters.institution}
                                onChange={handleChangeInstitution}
                                input={<Input/>}
                                MenuProps={MenuProps}
                                IconComponent={ExpandMoreIcon}
                            >
                                <StyledMenuItem key={"0"} value={"0"}>
                                    <FilterListItemText primary={"All Institutions"} color={colors.blue2}/>
                                </StyledMenuItem>
                                {institutions.map(institution => (
                                    <StyledMenuItem key={institution.institution_id} value={institution.institution_id}>
                                        <FilterListItemText
                                            primaryTypographyProps={{ sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
                                            primary={institution.institution_name} color={colors.blue2}
                                        />
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </FilterFormControl>
                    </Grid>
                </FilterContainer>
            </Grid>
        </BaseFilter>
    );
};

export {InstitutionFilter};
