import { useEffect } from 'react';
import * as React from 'react';
import { FilterContainer, FilterItem, Label } from '../styledComponents';
import { BaseFilter } from './baseFilter';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import { usePrevious } from '../../hooks/usePrevious';

interface InsuranceFilterProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

const InsuranceFilter: React.FunctionComponent<InsuranceFilterProps> = ({
  onClickButton,
  filters,
  setFiltersState,
}) => {
  const prevNeedsLifeInsurance = usePrevious(String(filters.needsLifeInsurance)) || null;
  const prevNeedsDisabilityCoverage = usePrevious(String(filters.needsDisabilityCoverage)) || null;
  const prevNeedsUmbrellaCoverage = usePrevious(String(filters.needsUmbrellaCoverage)) || null;
  const prevNeedsReviewInsurance = usePrevious(String(filters.needsReviewInsurance)) || null;

  useEffect(() => {
    if (
      String(prevNeedsLifeInsurance) !== String(filters.needsLifeInsurance) ||
      String(prevNeedsDisabilityCoverage) !== String(filters.needsDisabilityCoverage) ||
      String(prevNeedsUmbrellaCoverage) !== String(filters.needsUmbrellaCoverage) ||
      String(prevNeedsReviewInsurance) !== String(filters.needsReviewInsurance)
    ) {
      onClickButton();
    }
  }, [
    filters,
    prevNeedsLifeInsurance,
    prevNeedsDisabilityCoverage,
    prevNeedsUmbrellaCoverage,
    prevNeedsReviewInsurance,
    onClickButton,
  ]);

  return (
    <BaseFilter title="Insurance">
      <Grid container direction="column" justifyContent="space-between">
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Life Insurance</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.needsLifeInsurance === true}
                onClick={() => {
                  filters.needsLifeInsurance !== true
                    ? setFiltersState({ needsLifeInsurance: true })
                    : setFiltersState({ needsLifeInsurance: null });
                }}
              >
                Needs
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.needsLifeInsurance === false}
                onClick={() => {
                  filters.needsLifeInsurance !== false
                    ? setFiltersState({ needsLifeInsurance: false })
                    : setFiltersState({ needsLifeInsurance: null });
                }}
              >
                Doesn't Need
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Disability Coverage</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.needsDisabilityCoverage === true}
                onClick={() => {
                  filters.needsDisabilityCoverage !== true
                    ? setFiltersState({ needsDisabilityCoverage: true })
                    : setFiltersState({ needsDisabilityCoverage: null });
                }}
              >
                Needs
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.needsDisabilityCoverage === false}
                onClick={() => {
                  filters.needsDisabilityCoverage !== false
                    ? setFiltersState({ needsDisabilityCoverage: false })
                    : setFiltersState({ needsDisabilityCoverage: null });
                }}
              >
                Doesn't Need
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Umbrella Coverage</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.needsUmbrellaCoverage === true}
                onClick={() => {
                  filters.needsUmbrellaCoverage !== true
                    ? setFiltersState({ needsUmbrellaCoverage: true })
                    : setFiltersState({ needsUmbrellaCoverage: null });
                }}
              >
                Needs
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.needsUmbrellaCoverage === false}
                onClick={() => {
                  filters.needsUmbrellaCoverage !== false
                    ? setFiltersState({ needsUmbrellaCoverage: false })
                    : setFiltersState({ needsUmbrellaCoverage: null });
                }}
              >
                Doesn't Need
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Needs to Review Property and Casualty Insurance</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsReviewInsurance === true}
                onClick={() => {
                  filters.needsReviewInsurance !== true
                    ? setFiltersState({ needsReviewInsurance: true })
                    : setFiltersState({ needsReviewInsurance: null });
                }}
              >
                Does
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsReviewInsurance === false}
                onClick={() => {
                  filters.needsReviewInsurance !== false
                    ? setFiltersState({ needsReviewInsurance: false })
                    : setFiltersState({ needsReviewInsurance: null });
                }}
              >
                Doesn&apos;t
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { InsuranceFilter };
