import {AppThunkAction} from './../';
import {REQUEST_USER, REQUEST_USERS, UPDATE_FILTERS} from './actionTypes';
import adminApi from '../../services/adminApi';
import {DashboardResponse} from '../../models/DashboardResponse';
import {UserDetails} from '../../models/UserDetails';
import {FilterRequest} from '../../models/FilterRequest';

interface RequestUsersAction {
    type: typeof REQUEST_USERS;
    payload: DashboardResponse;
}

interface RequestUserAction {
    type: typeof REQUEST_USER;
    payload: UserDetails;
}

interface UpdateFiltersAction {
    type: typeof UPDATE_FILTERS;
    payload: FilterRequest;
}

export type DashboardAction = RequestUsersAction | RequestUserAction | UpdateFiltersAction;

export const DashboardActionCreators = {
    requestUsers: (page: number): AppThunkAction<DashboardAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dashboard) {
            try {
                adminApi.defaults.headers.Authorization = localStorage.getItem('token');
                await adminApi.get('users?page=' + (page + 1)).then(response => {
                    dispatch({
                        type: REQUEST_USERS,
                        payload: response.data,
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
    },
    requestUser: (path: string): AppThunkAction<RequestUserAction> => async (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dashboard) {
            try {
                adminApi.defaults.headers.Authorization = localStorage.getItem('token');
                await adminApi.get(path).then(response => {
                    dispatch({
                        type: REQUEST_USER,
                        payload: response.data,
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
    },
    filterUsers: (page: number, filters?: FilterRequest): AppThunkAction<DashboardAction> => async (
        dispatch,
        getState,
    ) => {
        const appState = getState();
        if (appState && appState.dashboard) {
            try {
                adminApi.defaults.headers.Authorization = localStorage.getItem('token');
                await adminApi.post('users?page=' + (page + 1), filters).then(response => {
                    dispatch({
                        type: REQUEST_USERS,
                        payload: response.data,
                    });
                });
            } catch (err) {
                console.log(err);
            }
        }
    },
    updateFilters: (filters: FilterRequest): AppThunkAction<DashboardAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.dashboard) {
            try {
                dispatch({
                    type: UPDATE_FILTERS,
                    payload: filters,
                });
            } catch (err) {
                console.log(err);
            }
            return filters;
        }
    },
};
