import { Action, Reducer } from 'redux';
import { UserSummaryState } from './state';
import { UserSummaryAction, UserPDFAction } from './actions';
import { REQUEST_USER_SUMMARY, SEND_REQUEST, REQUEST_USER_PDF } from './actionTypes';

const initialState: UserSummaryState = {
  userSummary: undefined,
  loading: false,
};

export const UserSummaryReducer: Reducer<UserSummaryState> = (
  state: UserSummaryState | undefined,
  incomingAction: Action,
): UserSummaryState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as UserSummaryAction | UserPDFAction;
  switch (action.type) {
    case SEND_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_USER_SUMMARY:
      return {
        ...state,
        userSummary: action.payload,
        loading: false,
      };
      case REQUEST_USER_PDF:
        return{
          ...state,
          userPDF: action.payload,
          loading: false,
        };
    default:
      break;
  }

  return state;
};
