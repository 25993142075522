import * as React from 'react';
import styled from 'styled-components';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionActions';
import { Grid } from '@mui/material';
import { Label, StyledExpansionPanel, SectionTitle, SubLabel, SectionContainer } from '../styledComponents';
import { colors } from '../../constants/colors';
import { EstatePlanning } from '../../models/UserSummary';
import { isObjectEmpty } from '../../constants/utils';
import EmptySectionMessage from './emptySectionMessage';

const GridBorderTop = styled(Grid)`
  border-top: 1px solid ${colors.gray2};
  padding: 15px 5px 20px 5px;
  width: 30%;
`;

interface EstatePlanningProps {
  estatePlanning: EstatePlanning;
}

const EstatePlanningSection: React.FunctionComponent<EstatePlanningProps> = ({ estatePlanning }) => {
  return (
    <StyledExpansionPanel>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="large" style={{ color: colors.blue1 }} />}
        aria-controls="panel-overview"
        id="panel1a-header"
      >
        <SectionTitle>Estate Planning</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>
        {estatePlanning && !isObjectEmpty(estatePlanning) ? (
          <SectionContainer container direction="column" justifyContent="space-between">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Has wills?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{estatePlanning.will}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Has guardianship?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{estatePlanning.guardianship_documents}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
              <GridBorderTop item>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Label>Needs to review?</Label>
                  </Grid>
                  <Grid item>
                    <SubLabel>{estatePlanning.reviewed_documents_in_past_three_years}</SubLabel>
                  </Grid>
                </Grid>
              </GridBorderTop>
            </Grid>
          </SectionContainer>
        ) : (
          <SectionContainer container direction="column" justifyContent="space-between">
            <EmptySectionMessage message={'hasn’t completed the ‘Estate & Wills’ section yet.'} />
          </SectionContainer>
        )}
      </AccordionDetails>
    </StyledExpansionPanel>
  );
};

export { EstatePlanningSection };
