import { useState, useEffect } from 'react';
import * as React from 'react';
import {
  FilterContainer,
  FilterInput,
  FilterItem,
  Label,
} from '../styledComponents';
import { BaseFilter } from './baseFilter';
import { Grid } from '@mui/material';
import { FiltersButton } from '../filtersButton';
import InputAdornment from '@mui/material/InputAdornment';
import { usePrevious } from '../../hooks/usePrevious';


interface TaxProfileProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}


const TaxProfileFilter: React.FunctionComponent<TaxProfileProps> = ({ onClickButton, filters, setFiltersState }) => {
  const [hasMunicipalBonds, setHasMunicipalBonds] = useState<boolean | null>(null);
  const [hasTaxableBonds, setHasTaxableBonds] = useState<boolean | null>(null);
  const [minAvgRate, setMinAvgRate] = useState<number>();
  const [maxAvgRate, setMaxAvgRate] = useState<number>();


  const prevInAMT = usePrevious(String(filters.alternativeMinTax)) || null;

  useEffect(() => {
    if (
      String(prevInAMT) !== String(filters.alternativeMinTax)
    ) {
      onClickButton();
    }
  }, [
    filters,
    prevInAMT,
    onClickButton,
  ]);


  return (
    <BaseFilter title="Tax Profile">
      <Grid container direction="column" justifyContent="space-between">
    {/*     <FilterContainer container direction="column" justify="space-between">
        <Grid item>
            <FilterFormControl variant="outlined" >
              <InputLabel id="tax-checkbox-label">Select Tax Brackets</InputLabel>
              <Select
                id="tax-checkbox"
                multiple
                value={taxBrackets}
                onChange={handleChange}
                input={<Input />}
                renderValue={selected => (selected as string[]).join(', ')}
                MenuProps={MenuProps}
                IconComponent={ExpandMoreIcon}
              > 
                {Taxes.map(tax => (
                  <StyledMenuItem key={tax} value={tax}>
                    <Checkbox checked={taxBrackets.indexOf(tax) > -1} color="primary" style={{ color: colors.blue1 }} />
                    <ListItemText primary={tax + '%'} color={colors.blue2} />
                  </StyledMenuItem>
                ))}
              </Select>
            </FilterFormControl>
          </Grid>               
        </FilterContainer>
        */}
        <Grid container direction="column" justifyContent="space-between">
          {/* this has never worked, removing it from UX for this release - consider re-adding when data is good */}
          {/*
          <FilterContainer container direction="row" justifyContent="space-between">
            <FilterItem item>
              <Label>Min Avg. Rate</Label>
              <FilterInput
                id="minAvgRate"
                placeholder="0"
                InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                variant="outlined"
                value={minAvgRate || ''}
                onChange={e => setMinAvgRate(parseFloat(e.target.value))}
                type="number"
                  disabled
              />
            </FilterItem>
            <FilterItem item>
              <Label>Max Avg. Rate</Label>
              <FilterInput
                id="maxAvgRate"
                placeholder="100"
                InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                variant="outlined"
                value={maxAvgRate || ''}
                onChange={e => setMaxAvgRate(parseFloat(e.target.value))}
                type="number"
                  disabled
              />
            </FilterItem>
          </FilterContainer>
          <FilterContainer container direction="column" justifyContent="space-between">
            <Grid item>
              <Label>Municipal Bonds</Label>
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <FiltersButton
                  sm
                  variant="outlined"
                  active={hasMunicipalBonds === true}
                  onClick={() => {
                    hasMunicipalBonds !== true ? setHasMunicipalBonds(true) : setHasMunicipalBonds(null);
                  }}
                  disabled
                >
                  Has
                </FiltersButton>
              </Grid>
              <Grid item>
                <FiltersButton
                  bg
                  variant="outlined"
                  active={hasMunicipalBonds === false}
                  onClick={() => {
                    hasMunicipalBonds !== false ? setHasMunicipalBonds(false) : setHasMunicipalBonds(null);
                  }}
                  disabled
                >
                  Doesn't Have
                </FiltersButton>
              </Grid>
            </Grid>
          </FilterContainer>
          <FilterContainer container direction="column" justifyContent="space-between">
            <Grid item>
              <Label>Taxable Bonds</Label>
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <FiltersButton
                  sm
                  variant="outlined"
                  active={hasTaxableBonds === true}
                  onClick={() => {
                    hasTaxableBonds !== true ? setHasTaxableBonds(true) : setHasTaxableBonds(null);
                  }}
                  disabled
                >
                  Has
                </FiltersButton>
              </Grid>
              <Grid item>
                <FiltersButton
                  bg
                  variant="outlined"
                  active={hasTaxableBonds === false}
                  onClick={() => {
                    hasTaxableBonds !== false ? setHasTaxableBonds(false) : setHasTaxableBonds(null);
                  }}
                  disabled
                >
                  Doesn't Have
                </FiltersButton>
              </Grid>
            </Grid>
          </FilterContainer>
          */}
          <FilterContainer container direction="column" justifyContent="space-between">
            <Grid item>
              <Label>Alternative Minimum Tax</Label>
            </Grid>
            <Grid container direction="row" justifyContent="space-between">
              <FilterItem item>
                <FiltersButton
                  variant="outlined"
                  active={filters.alternativeMinTax === true}
                  onClick={() => {
                    (filters.alternativeMinTax !== true) 
                    ? setFiltersState({alternativeMinTax: true}) 
                    : setFiltersState({alternativeMinTax: null});
                  }}
                >
                  In AMT
                </FiltersButton>
              </FilterItem>
              <FilterItem item>
                <FiltersButton
                  variant="outlined"
                  active={filters.alternativeMinTax === false}
                  onClick={() => {
                    (filters.alternativeMinTax !== false) 
                    ? setFiltersState({alternativeMinTax: false}) 
                    : setFiltersState({alternativeMinTax: null});
                  }}
                >
                  Not in AMT
                </FiltersButton>
              </FilterItem>
            </Grid>
          </FilterContainer>
        </Grid>
      </Grid>
    </BaseFilter>
  );
};

export { TaxProfileFilter };
