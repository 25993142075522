import { useEffect } from 'react';
import * as React from 'react';
import { FilterContainer, FilterItem, Label } from '../styledComponents';
import { Grid } from '@mui/material';
import { BaseFilter } from './baseFilter';
import { FiltersButton } from '../filtersButton';
import { usePrevious } from '../../hooks/usePrevious';

interface EstatePlanningFilterProps {
  onClickButton: any;
  filters: any;
  setFiltersState: any;
}

const EstatePlanningFilter: React.FunctionComponent<EstatePlanningFilterProps> = ({
  onClickButton,
  filters,
  setFiltersState,
}) => {
  const prevHasWills = usePrevious(String(filters.hasWills)) || null;
  const prevHasGuardianship = usePrevious(String(filters.hasGuardianship)) || null;
  const prevNeedsReviewEstate = usePrevious(String(filters.needsReviewEstate)) || null;

  useEffect(() => {
    if (
      String(prevHasWills) !== String(filters.hasWills) ||
      String(prevHasGuardianship) !== String(filters.hasGuardianship) ||
      String(prevNeedsReviewEstate) !== String(filters.needsReviewEstate)
    ) {
      onClickButton();
    }
  }, [filters, prevHasWills, prevHasGuardianship, prevNeedsReviewEstate, onClickButton]);

  return (
    <BaseFilter title="Estate Planning">
      <Grid container direction="column" justifyContent="space-between">
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Wills</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasWills === true}
                onClick={() => {
                  filters.hasWills !== true ? setFiltersState({ hasWills: true }) : setFiltersState({ hasWills: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasWills === false}
                onClick={() => {
                  filters.hasWills !== false
                    ? setFiltersState({ hasWills: false })
                    : setFiltersState({ hasWills: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Guardianship Documents</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <FiltersButton
                sm
                variant="outlined"
                active={filters.hasGuardianship === true}
                onClick={() => {
                  filters.hasGuardianship !== true
                    ? setFiltersState({ hasGuardianship: true })
                    : setFiltersState({ hasGuardianship: null });
                }}
              >
                Has
              </FiltersButton>
            </Grid>
            <Grid item>
              <FiltersButton
                bg
                variant="outlined"
                active={filters.hasGuardianship === false}
                onClick={() => {
                  filters.hasGuardianship !== false
                    ? setFiltersState({ hasGuardianship: false })
                    : setFiltersState({ hasGuardianship: null });
                }}
              >
                Doesn't Have
              </FiltersButton>
            </Grid>
          </Grid>
        </FilterContainer>
        <FilterContainer container direction="column" justifyContent="space-between">
          <Grid item>
            <Label>Needs to Review Estate</Label>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsReviewEstate === true}
                onClick={() => {
                  filters.needsReviewEstate !== true
                    ? setFiltersState({ needsReviewEstate: true })
                    : setFiltersState({ needsReviewEstate: null });
                }}
              >
                Does
              </FiltersButton>
            </FilterItem>
            <FilterItem item>
              <FiltersButton
                variant="outlined"
                active={filters.needsReviewEstate === false}
                onClick={() => {
                  filters.needsReviewEstate !== false
                    ? setFiltersState({ needsReviewEstate: false })
                    : setFiltersState({ needsReviewEstate: null });
                }}
              >
                Doesn&apos;t
              </FiltersButton>
            </FilterItem>
          </Grid>
        </FilterContainer>
      </Grid>
    </BaseFilter>
  );
};

export { EstatePlanningFilter };
