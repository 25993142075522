import * as React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Add, Remove } from '@mui/icons-material';
import { SectionTitle, FiltersExpansionPanel } from '../styledComponents';
import { colors } from '../../constants/colors';

interface BaseFilterProps {
  title: string;
  children?: any;
}

const BaseFilter: React.FunctionComponent<BaseFilterProps> = ({ title, children }) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <FiltersExpansionPanel expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        expandIcon={
          expanded ? (
            <Remove fontSize="small" style={{ color: colors.blue1 }} />
          ) : (
            <Add fontSize="small" style={{ color: colors.blue1 }} />
          )
        }
        aria-controls="panel-overview"
        id="panel1-header"
      >
        <SectionTitle>{title}</SectionTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </FiltersExpansionPanel>
  );
};

export { BaseFilter };
