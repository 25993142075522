import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { ApplicationState } from '../../store';
import { colors } from '../../constants/colors';
import { GridNoBorder, SubLabel } from '../styledComponents';

const Title = styled.p`
  margin: 0 0 22px 0;
  font-weight: bold;
  font-size: 22px;
  color: ${colors.blue2};
`;

type EmptySectionProps = {
  message: string;
};

type OwnProps = ReturnType<typeof mapStateToProps> & EmptySectionProps;

const EmptySectionMessage: React.FunctionComponent<OwnProps> = ({ message, userSummary }) => {
  return (
    <GridNoBorder container direction="column" justifyContent="center" alignItems="center">
      <Grid item>
        <Title>Nothing Here Yet!</Title>
      </Grid>
      <Grid item>
        <SubLabel>
          {userSummary.userSummary && userSummary.userSummary.user_data && userSummary.userSummary.user_data.first_name
            ? userSummary.userSummary.user_data.first_name
            : 'User'}{' '}
          {message}
        </SubLabel>
      </Grid>
    </GridNoBorder>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  userSummary: state.userSummary,
});

export default connect(mapStateToProps)(EmptySectionMessage);
