import { Action, Reducer } from 'redux';
import { DashboardState } from './state';
import { DashboardAction } from './actions';
import { REQUEST_USER, REQUEST_USERS, UPDATE_FILTERS } from './actionTypes';

const initialFilters = {
  states: null,
  badges: null,
  hasChildren: null,
  hasDependents: null,
  hasEmergencyFund: null,
  isHomeowner: null,
  isVehicleOwner: null,
  minTodos: null,
  maxTodos: null,
  todoByName: null,
  minAssets: null,
  maxAssets: null,
  minDebt: null,
  maxDebt: null,
  minNetWorth: null,
  maxNetWorth: null,
  minIncome: null,
  maxIncome: null,
  minExpenses: null,
  maxExpenses: null,
};

const initialState: DashboardState = {
  data: [],
  links: undefined,
  meta: undefined,
  userDetails: undefined,
  filters: initialFilters,
};

export const DashboardReducer: Reducer<DashboardState> = (
  state: DashboardState | undefined,
  incomingAction: Action,
): DashboardState => {
  if (state === undefined) {
    return initialState;
  }

  const action = incomingAction as DashboardAction;
  switch (action.type) {
    case REQUEST_USERS:
      return {
        ...state,
        data: action.payload.data,
        links: action.payload.links,
        meta: action.payload.meta,
      };
    case REQUEST_USER:
      return {
        ...state,
        userDetails: action.payload,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
  }

  return state;
};
